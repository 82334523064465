import uiBootstrapModule from '../../../vendors/ui-bootstrap-custom/ui-bootstrap.module.js';
import rangeSliderModule from '../../../vendors/rangeSlider/rangeSlider.module.js';
import popoverModule from '../../_common/popover/popover.module.js';
import './styles/catalogFilter.scss';
import catalogFilterService from './services/catalogFilterService.js';
import { catalogFilterDirective, catalogFilterSortDirective, catalogFilterSelectSortDirective, catalogFilterOnMainDirective } from './directives/catalogFilterDirectives.js';
import CatalogFilterCtrl from './controllers/catalogFilterController.js';
import CatalogFilterSortCtrl from './controllers/catalogFilterSortController.js';
import CatalogFilterOnMainCtrl from './controllers/catalogFilterOnMain.js';
const moduleName = 'catalogFilter';
angular.module(moduleName, [rangeSliderModule, uiBootstrapModule, popoverModule]).constant('catalogFilterAdvPopoverOptionsDefault', {
  position: 'left',
  isFixed: false,
  showOnLoad: false,
  overlayEnabled: false
}).service('catalogFilterService', catalogFilterService).controller('CatalogFilterCtrl', CatalogFilterCtrl).controller('CatalogFilterSortCtrl', CatalogFilterSortCtrl).controller('CatalogFilterOnMainCtrl', CatalogFilterOnMainCtrl).directive('catalogFilter', catalogFilterDirective).directive('catalogFilterSort', catalogFilterSortDirective).directive('catalogFilterSelectSort', catalogFilterSelectSortDirective).directive('catalogFilterOnMain', catalogFilterOnMainDirective);
export default moduleName;