CatalogFilterOnMainCtrl.$inject = ["$http", "$window", "$timeout", "catalogFilterService", "domService", "toaster"];

/*@ngInject*/
function CatalogFilterOnMainCtrl($http, $window, $timeout, catalogFilterService, domService, toaster) {
  var ctrl = this,
      timerRange;
  ctrl.IsShowFilterExtended = false;
  ctrl.IsRangeItemsExist = false;
  ctrl.CountProductByCurrentFilter = 0;
  ctrl.Url = "";
  ctrl.parameters = {};

  ctrl.$onInit = function () {
    ctrl.getProductTypeList();
  };

  ctrl.getProductTypeList = function () {
    $http.post('/Catalog/GetProductCategoriesList').then(function (response) {
      if (response.data != null) {
        ctrl.ProductTypeList = response.data;
        ctrl.SelectedProductType = ctrl.ProductTypeList[0];
        ctrl.parametrsString = "categoryId=" + ctrl.SelectedProductType.CategoryId;
        ctrl.parameters.categoryId = ctrl.SelectedProductType.CategoryId;
        ctrl.changeProductType();
      }
    });
  };

  ctrl.changeProductType = function () {
    ctrl.parametrsString = "categoryId=" + ctrl.SelectedProductType.CategoryId;
    ctrl.getFilterByCategoryId();
    ctrl.parameters.categoryId = ctrl.SelectedProductType.CategoryId;
    ctrl.totalUrl = ctrl.Url;
  };

  ctrl.getFilterByCategoryId = function () {
    //$http.get(`/Catalog/Filter?${ctrl.parametrsString}`).then(function (response) {
    //    if (response.data != null) {
    //        ctrl.IsRangeItemsExist = false;
    //        ctrl.IsShowFilterExtended = false;
    //        ctrl.FilterData = response.data;
    //        //Первая статическая часть фильтра
    //        ctrl.FilterDataPriceAndSize = [];
    //        //Первая динамическая часть фильтра
    //        ctrl.FilterDataAbbreviated = [];
    //        //Для расширенного фильтра 
    //        ctrl.FilterDataExtendedLeft = [];
    //        //Для расширенного фильтра 
    //        ctrl.FilterDataExtendedRight = [];
    //        for (var i = 0; i < ctrl.FilterData.length; i++) {
    //            if (ctrl.FilterData[i].Type == "price" || ctrl.FilterData[i].Type == "size") {
    //                ctrl.FilterDataPriceAndSize.push(ctrl.FilterData[i]);
    //            }
    //            else {
    //                //Если выбрана категория Взрослые матрасы/Детские матрасы/НаматрасникиТоперы : Динамическая часть состоит из (Жесткость стороны 1, Жесткость стороны 2). Если категории Кровати, то (Тип кровати, Базовая комплектация)
    //                if (ctrl.FilterData[i].Title == "Жесткость 1-ой стороны" || ctrl.FilterData[i].Title == "Жесткость 2-ой стороны"
    //                    || ctrl.FilterData[i].Title == "Тип кровати" || (ctrl.FilterData[i].Title == "Базовая комплектация" && ctrl.SelectedProductType.CategoryId == 5676)
    //                    || ctrl.FilterData[i].Title == "Жесткость стороны 1 наматрасника" || ctrl.FilterData[i].Title == "Жесткость стороны 2 наматрасника") {
    //                    ctrl.FilterDataAbbreviated.push(ctrl.FilterData[i]);
    //                }
    //                else {
    //                    //В фильтре используются не все свойства категории
    //                    //Взрослые матрасы
    //                    if (ctrl.SelectedProductType.CategoryId == 5672) {
    //                        if (ctrl.FilterData[i].Title == "Тип матраса" || ctrl.FilterData[i].Title == "Наполнители" || ctrl.FilterData[i].Title == "Упаковка" || ctrl.FilterData[i].Title == "Особые свойства" || ctrl.FilterData[i].Type == "brand") {
    //                            ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
    //                        }
    //                        if (ctrl.FilterData[i].Control == "range" ) {
    //                            ctrl.FilterDataExtendedLeft.push(ctrl.FilterData[i]);
    //                            ctrl.IsRangeItemsExist = true;
    //                        }
    //                    }
    //                    //Детские матрасы
    //                    else if (ctrl.SelectedProductType.CategoryId == 5674) {
    //                        if (ctrl.FilterData[i].Title == "Тип матраса" || ctrl.FilterData[i].Type == "brand"|| ctrl.FilterData[i].Title == "Тип" || ctrl.FilterData[i].Title == "Наполнители" || ctrl.FilterData[i].Title == "Особые свойства") {
    //                            ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
    //                        }
    //                        if (ctrl.FilterData[i].Control == "range") {
    //                            ctrl.FilterDataExtendedLeft.push(ctrl.FilterData[i]);
    //                            ctrl.IsRangeItemsExist = true;
    //                        }
    //                    }
    //                    //Наматрасники
    //                    else if (ctrl.SelectedProductType.CategoryId == 5677) {
    //                        if (ctrl.FilterData[i].Title == "Тип наматрасника" || ctrl.FilterData[i].Title == "Производители" || ctrl.FilterData[i].Title == "Особые свойства наматрасника" || ctrl.FilterData[i].Title == "Наполнители") {
    //                            ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
    //                        }
    //                        if (ctrl.FilterData[i].Title == "Толщина наматрасника" ) {
    //                            ctrl.FilterDataExtendedLeft.push(ctrl.FilterData[i]);
    //                            ctrl.IsRangeItemsExist = true;
    //                        }
    //                    }
    //                    //Кровати
    //                    else if (ctrl.SelectedProductType.CategoryId == 5676) {
    //                        if (ctrl.FilterData[i].Title == "Материал каркаса" || ctrl.FilterData[i].Title == "Материал обивки" || ctrl.FilterData[i].Title == "Производители") {
    //                            ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
    //                        }
    //                    }
    //                }
    //            }
    //        } 
    //        ctrl.CategoryUrlPath = ctrl.SelectedProductType.UrlPath;
    //        ctrl.Url = "categories/" + ctrl.CategoryUrlPath;
    //        ctrl.TotalUrl = ctrl.Url;
    //        ctrl.getFilterCount(null).then(function (foundCount) {
    //            ctrl.CountProductByCurrentFilter = foundCount;
    //        });
    //    }
    //});
    $http.get("/Catalog/GetPropsOnFilter?".concat(ctrl.parametrsString)).then(function (response) {
      if (response.data != null) {
        ctrl.IsRangeItemsExist = false;
        ctrl.IsShowFilterExtended = false;
        ctrl.FilterData = response.data; //Первая статическая часть фильтра

        ctrl.FilterDataPriceAndSize = []; //Первая динамическая часть фильтра

        ctrl.FilterDataAbbreviated = []; //Для расширенного фильтра 

        ctrl.FilterDataExtendedLeft = []; //Для расширенного фильтра 

        ctrl.FilterDataExtendedRight = []; //Для расширенного фильтра

        ctrl.FilterDataExtendedRightSelect = [];

        for (var i = 0; i < ctrl.FilterData.length; i++) {
          if (ctrl.FilterData[i].Type == "price" || ctrl.FilterData[i].Type == "size") {
            ctrl.FilterDataPriceAndSize.push(ctrl.FilterData[i]);
          } else {
            //Если выбрана категория Взрослые матрасы/Детские матрасы/НаматрасникиТоперы : Динамическая часть состоит из (Жесткость стороны 1, Жесткость стороны 2). 
            //Если категории Кровати, то(Тип кровати, Базовая комплектация)
            if (ctrl.FilterData[i].Title == "Жесткость стороны 1" || ctrl.FilterData[i].Title == "Жесткость стороны 2" || (ctrl.FilterData[i].Title == "Комплектация" || ctrl.FilterData[i].Title == "Тип кровати") && ctrl.SelectedProductType.CategoryId == 5676 || ctrl.FilterData[i].Title == "Жесткость стороны 1 наматрасника" || ctrl.FilterData[i].Title == "Жесткость стороны 2 наматрасника") {
              ctrl.FilterDataAbbreviated.push(ctrl.FilterData[i]);
            } else {
              //В фильтре используются не все свойства категории
              //Взрослые матрасы
              if (ctrl.SelectedProductType.CategoryId == 5672) {
                if (ctrl.FilterData[i].Title == "Тип матраса" || ctrl.FilterData[i].Title == "Наполнители" || ctrl.FilterData[i].Title == "Особые свойства" || ctrl.FilterData[i].Type == "brand") {
                  ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
                }

                if (ctrl.FilterData[i].Control == "range") {
                  ctrl.FilterDataExtendedLeft.push(ctrl.FilterData[i]);
                  ctrl.IsRangeItemsExist = true;
                }

                if (ctrl.FilterData[i].Title == "Упаковка") {
                  ctrl.FilterDataExtendedRightSelect.push(ctrl.FilterData[i]);
                  ctrl.IsRangeItemsExist = true;
                }
              } //Детские матрасы
              else if (ctrl.SelectedProductType.CategoryId == 5674) {
                if (ctrl.FilterData[i].Title == "Тип матраса" || ctrl.FilterData[i].Type == "brand" || ctrl.FilterData[i].Title == "Тип" || ctrl.FilterData[i].Title == "Наполнители" || ctrl.FilterData[i].Title == "Особые свойства") {
                  ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
                }

                if (ctrl.FilterData[i].Control == "range") {
                  ctrl.FilterDataExtendedLeft.push(ctrl.FilterData[i]);
                  ctrl.IsRangeItemsExist = true;
                }
              } //Наматрасники
              else if (ctrl.SelectedProductType.CategoryId == 5677) {
                if (ctrl.FilterData[i].Title == "Тип наматрасника" || ctrl.FilterData[i].Type == "brand" || ctrl.FilterData[i].Title == "Особые свойства наматрасника" || ctrl.FilterData[i].Title == "Наполнители наматрасника") {
                  ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
                }

                if (ctrl.FilterData[i].Title == "Толщина наматрасника") {
                  ctrl.FilterDataExtendedLeft.push(ctrl.FilterData[i]);
                  ctrl.IsRangeItemsExist = true;
                }

                if ("Хранение наматрасника в свернутом виде" == ctrl.FilterData[i].Title) {
                  ctrl.FilterDataExtendedRightSelect.push(ctrl.FilterData[i]);
                  ctrl.IsRangeItemsExist = true;
                }
              } //Кровати
              else if (ctrl.SelectedProductType.CategoryId == 5676) {
                if (ctrl.FilterData[i].Title == "Материал каркаса" || ctrl.FilterData[i].Title == "Материал обивки" || ctrl.FilterData[i].Type == "brand") {
                  ctrl.FilterDataExtendedRight.push(ctrl.FilterData[i]);
                }
              }
            }
          }
        }

        ctrl.CategoryUrlPath = ctrl.SelectedProductType.UrlPath;
        ctrl.Url = "categories/" + ctrl.CategoryUrlPath;
        ctrl.TotalUrl = ctrl.Url;
        ctrl.getFilterCount(null).then(function (foundCount) {
          ctrl.CountProductByCurrentFilter = foundCount;
        });
      }
    });
  };

  ctrl.showFilterExtended = function () {
    ctrl.IsShowFilterExtended = !ctrl.IsShowFilterExtended;
  };

  ctrl.clickSelect = function ($event, indexFilter) {
    var element = $event.currentTarget.parentNode.parentNode;

    if (element != null) {
      ctrl.changeItem(element, indexFilter);
    }
  };

  ctrl.clickCheckbox = function ($event, indexFilter) {
    var element = domService.closest($event.target, '.catalog-filter-row');

    if (element != null) {
      ctrl.changeItem(element, indexFilter);
    }
  };

  ctrl.clickRange = function (event, indexFilter) {
    var element = domService.closest(ctrl.rangeElementClicked, '.filter-on-main-range');
    ctrl.changeItem(element, indexFilter); //if (timerRange != null) {
    //    $timeout.cancel(timerRange);
    //}
    //ctrl.rangeElementClicked = ctrl.rangeElementClicked || event.target;
    //timerRange = $timeout(function () {
    //    var element = domService.closest(ctrl.rangeElementClicked, '.filter-on-main-range');
    //    if (element != null) {
    //        ctrl.changeItem(element, indexFilter);
    //    }
    //    ctrl.rangeElementClicked = null;
    //}, 500);
  };

  ctrl.clickRangeDown = function (event) {
    ctrl.rangeElementClicked = event.target;
  };

  ctrl.changeItem = function (element, indexFilter) {
    var selectedItems, params;

    if (indexFilter != null) {
      ctrl.FilterData[indexFilter].dirty = true;
    }

    selectedItems = catalogFilterService.getSelectedData(ctrl.FilterData);
    params = catalogFilterService.buildUrl(selectedItems);
    ctrl.TotalUrl = ctrl.Url + '?' + params;
    ctrl.getFilterCount(params).then(function (foundCount) {
      ctrl.CountProductByCurrentFilter = foundCount;
    });
  };

  ctrl.getFilterCount = function (filterString) {
    return $http.get('/Catalog/filterproductCount' + (filterString != null && filterString.length > 0 ? '?' + filterString : ''), {
      params: angular.extend(ctrl.parameters, {
        rnd: Math.random()
      })
    }).then(function (response) {
      return response.data;
    });
  };

  ctrl.reset = function () {
    ctrl.getFilterByCategoryId();
    setTimeout(toaster.pop('success', '', "Фильтр очищается"), 60000);
  };
}

export default CatalogFilterOnMainCtrl;